import { Injectable } from '@angular/core';

import { AIConversationRole, SendMessageRequest } from 'src/models/ai-tester.js';

import { ApiService } from './api.service';

export interface TicketDetails {
  // TODO: this is the BE view of the messages, we should only send back a version made for this tester interface
  // This is copied from the BE
  // This is going to live here until we know how to properly bound what the BE is sending back to us
  summarySoFar: string;
  ticketTypeId: string | null;
  ticketTypeName: string | null;
  ticketTypeFields: Record<string, string>;
  priority: string;
  sentiment: number;
  title: string;
}

export type KnowledgeBaseArticleWithScore = {
  // TODO: this is the BE view of the messages, we should only send back a version made for this tester interface
  // This is copied from the BE
  // This is going to live here until we know how to properly bound what the BE is sending back to us
  id: string;
  title: string;
  content: string;
  url: string | null;
  knowledgeBaseArticleId: string;
  score: number;
};

export interface AgentMessage {
  role: AIConversationRole;
  content: string;
  suggestedTicketDetails?: TicketDetails;
  citations?: KnowledgeBaseArticleWithScore[];
}

export interface AgentSelection {
  name: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AITesterService {
  constructor(private apiService: ApiService) {}

  sendMessage(payload: SendMessageRequest): Promise<AgentMessage[]> {
    return this.apiService.postPromise('/ai-tester', payload);
  }

  listAgents(): Promise<AgentSelection[]> {
    return this.apiService.getPromise('/ai-tester/agents');
  }
}
